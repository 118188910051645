import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Link,
  Typography,
  Tooltip,
  Stack,
  Button,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import { useParams, Link as RouterLink, useLocation } from "react-router-dom";

import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { s3baseUrl, baseUri } from "src/config/config";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import {
  deleteLessonDocumentApi,
  documentListApi,
  documentListApiSlug,
} from "src/DAL/lessonDocument/LessonDocument";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import { makeStyles } from "@mui/styles";
import CustomPopover from "src/components/MenuOption/CustomPopover";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import RecordNotFound from "../../components/RecordNotFound";
const ITEM_HEIGHT = 48;

const imageLink = {
  docx: wordImage,
  mp3: audioImage,
  pdf: pdfImage,
  csv: csvImage,
  doc: docImage,
  xlsx: xlsxImage,
  xls: xlsxImage,
  other: otherImage,
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export const ResourcesCard = () => {
  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState("");

  const handleDownload = async (path) => {
    setIsLoading(true);
    const file_path = `${s3baseUrl}${path}`;
    fetch(file_path)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, path);
          setIsLoading(false);
        } else {
          enqueueSnackbar(res.statusText, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
        setIsLoading(false);
      });
  };
  const handleEdit = (value) => {
    navigate(`/programmes/lessons/edit-lesson-document/${params.slug}`, {
      state: value,
    });
  };
  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    //console.log(deleteDoc.document_slug, "delete function run");
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteLessonDocumentApi(deleteDoc.document_slug);
    if (result.code === 200) {
      getDocument();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getDocument = async () => {
    //console.log(params, "paramsparamsparamsparamsparamsparamsparamsparams");
    setIsWaiting(true);
    let result = await documentListApiSlug(params.slug);
    if (result.code == 200) {
      setIsWaiting(false);
      setDocumentList(result.lesson_document);
      //console.log(result, "apiresult");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsWaiting(false);
    }
  };
  const getResourceImage = (doc) => {
    if (doc.document_thumbnail) {
      return s3baseUrl + doc.document_thumbnail;
    } else if (doc.document_type == "image") {
      return s3baseUrl + doc?.document_images_url?.thumbnail_1;
    } else {
      const ext = doc.document_file_url?.split(".").pop();
      if (imageLink[ext]) {
        return imageLink[ext];
      } else {
        return imageLink.other;
      }
    }
  };
  const handleGetValue = () => {
    //console.log("ok clicked");
  };
  useEffect(() => {
    getDocument();
  }, []);
  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  //console.log(params, "paramsparams");
  if (isWaiting) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row">
          <div className="col-12">
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            {/* <button
            className="small-contained-button float-end mt-1"
            onClick={() => navigate(`/programmes/addreview/`)}
          >
            Add Programme Review
          </button> */}
            <button
              onClick={() =>
                navigate(
                  `/programmes/lessons/add-lesson-document/${params.slug}`
                )
              }
              className="small-contained-button float-end mt-1">
              Add Resource
            </button>
          </div>
          <div className="col-12">
            <h1>Lesson Resources</h1>
          </div>
          <div className="col-lg-12 col-sm-12 text-end"></div>
          {documentList.length < 1 ? (
            <RecordNotFound title="Lesson Resource" />
          ) : (
            documentList.map((value, i) => {
              return (
                <div className="col-sm-12 col-md-6 col-lg-6 mb-4" key={i}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100 ">
                    <div className="row card-body">
                      <div className="col-md-3">
                        <img
                          src={getResourceImage(value)}
                          className="pt-3 ps-3"
                          height="80px"
                          alt=""
                        />
                      </div>
                      <div className="col-md-7">
                        <div>
                          <h3 className="h2-heading">
                            {htmlDecode(value.title)}
                          </h3>
                        </div>
                        <p className="programme-card-desc mb-3">
                          {htmlDecode(value.detailed_description)}
                        </p>
                      </div>

                      <div className="col-md-2">
                        <div onClick={handleGetValue}>
                          <CustomPopover menu={MENU_OPTIONS} data={value} />
                        </div>
                        <div className="download-icon">
                          <Tooltip title="Download">
                            <DownloadIcon
                              onClick={() => {
                                let path = "";
                                if (value.document_type === "image") {
                                  path = value.document_images_url.thumbnail_1;
                                } else {
                                  path = value.document_file_url;
                                }
                                handleDownload(path);
                              }}
                              className="icon-style"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};
